<template>
  <div v-if="ready">
    <v-card-text v-for="(item, i) in variants" :key="i">
      <v-select
        v-model="variantValues[i]"
        v-on:change="showVariantStock"
        :items="item.values"
        :label="item.name"
        :item-text="(obj) => obj['text']"
        :item-value="(obj) => obj['value']"
        return-object
        :rules="[(v) => !!v || $vuetify.lang.t('$vuetify.mandatoryField')]"
        required
      ></v-select>
    </v-card-text>

    <v-card-actions v-if="addToCart">
      <v-spacer></v-spacer>

      <div>
        <v-btn large text @click="closeDialog"> {{ $vuetify.lang.t("$vuetify.cancel") }} </v-btn>
        <v-btn large :disabled="!isVariantCompleted" class="primary" @click="addVariant">
          {{ $vuetify.lang.t("$vuetify.add") }}
        </v-btn>
      </div>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  name: "ItemsVariants",
  props: {
    item: Object,
    variants: Object,
    addToCart: Boolean,
  },
  data: () => ({
    variantValues: {},
    ready: false,
  }),
  methods: {
    addVariant: function () {
      this.$emit("add-variant", this.item.id, this.variantValues);
    },
    closeDialog: function () {
      this.$emit("close-dialog");
    },
    showVariantStock: function () {
      if (this.isVariantCompleted) {
        this.$emit("show-variant-stock", this.item.id, this.variantValues);
      }
    },
  },
  computed: {
    isVariantCompleted() {
      return Object.keys(this.variants).length === Object.keys(this.variantValues).length;
    },
  },
  mounted() {
    for (const key in this.variants) {
      if (this.variants[key].values.length === 1) {
        this.variantValues[key] = this.variants[key].values[0];
      }
    }
    this.ready = true;
    this.showVariantStock();
  },
};
</script>

<style scoped></style>
