<template>
  <v-layout row wrap class="catalog-items-panel pl-md-5 pr-md-5 pr-lg-10 pl-xl-10 pr-xl-15">
    <v-flex xs6 sm4 md3 lg2 xl2 v-for="item in items" :key="item.id">
      <!--The card representing the Item's Group can be defined with 3 elements:
            1 - image   2 - title   3 - price
            It is mandatory to have an image to be displayed -->
      <v-card
        hover
        tile
        @click="addToCart(item)"
        :class="(selectedItemsIdentifiers || []).indexOf(item.id) >= 0 ? 'catalog-item-card-selected' : ''"
        class="catalog-item-card"
      >
        <v-img
          v-if="baseUrl && item && item.data && item.data.image"
          v-bind:src="baseUrl + item.data.image"
          aspect-ratio="1.48"
        >
          <span class="item-card-code">
            {{ item.code }}
          </span>

          <!-- Item's stock button in card with image -->
          <span class="item-card-options" v-if="isStockInfoAvailable" @click="showItemStock(item)" @click.stop>
            <v-btn icon>
              <v-icon small>{{ "fas fa-ellipsis-v" }}</v-icon>
            </v-btn>
          </span>
        </v-img>

        <v-img v-if="!item.data || !item.data.image" :src="require('@/assets/no-photo.jpg')" aspect-ratio="1.48">
          <span class="item-card-initials">
            {{
              item.description
                .split(" ")
                .reduce((result, currentWord) => result + currentWord.charAt(0).toUpperCase(), "")
                .substring(0, 2)
            }}
          </span>
          <span class="item-card-code">
            {{ item.code }}
          </span>

          <!-- Item's stock button in card without image -->
          <span class="item-card-options" v-if="isStockInfoAvailable" @click="showItemStock(item)" @click.stop>
            <v-btn icon>
              <v-icon small>{{ "fas fa-ellipsis-v" }}</v-icon>
            </v-btn>
          </span>
        </v-img>
        <v-card-title primary-title class="catalog-item-card-title">
          <div v-if="itemsDescription === '2' && item.mainDescription" class="headline">{{ item.mainDescription }}</div>
          <div v-else class="headline">{{ item.description }}</div>
        </v-card-title>
      </v-card>
    </v-flex>
    <v-dialog v-model="displayVariantsDialog" persistent max-width="600px">
      <ItemsVariantsCard
        v-if="displayVariantsDialog"
        :item="selectedItem"
        :variants="selectedItem.data.variants"
        @close-dialog="closeDialog"
        @add-variant="addVariant"
      >
      </ItemsVariantsCard>
    </v-dialog>
    <v-dialog v-model="displayOpenItemInputDialog" persistent max-width="600px">
      <OpenItemInputCard
        v-if="displayOpenItemInputDialog"
        :item="selectedItem"
        @close-dialog="closeDialog"
        @add-input="addInput"
      >
      </OpenItemInputCard>
    </v-dialog>
    <!-- https://dev.vuetifyjs.com/en/api/v-dialog/#props content-class -->
    <v-dialog v-model="displayMenuDialog" scrollable content-class="custom-dialog">
      <ItemsMenuLevelsCard
        v-if="displayMenuDialog"
        :item="selectedItem"
        :levels="selectedItem.data.menuLevels"
        @close-dialog="closeMenuDialog"
      >
      </ItemsMenuLevelsCard>
    </v-dialog>

    <v-dialog v-model="displayStockDialog" persistent max-width="600px">
      <ItemsStockCard
        v-if="displayStockDialog"
        :item="selectedItem"
        :variant="selectedVariant"
        :showStock="showStock"
        @close-dialog="closeDialog"
        @show-variant-stock="showVariantStock"
      ></ItemsStockCard>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import ItemsVariantsCard from "@/views/ItemsVariantsCard.vue";
import ItemsMenuLevelsCard from "@/views/ItemsMenuLevelsCard.vue";
import ItemsStockCard from "@/views/ItemsStockCard.vue";
import { getErrorMessage } from "@/_helpers";
import OpenItemInputCard from "@/views/OpenItemInputCard";

export default {
  name: "ItemsListCard",
  components: { OpenItemInputCard, ItemsMenuLevelsCard, ItemsVariantsCard, ItemsStockCard },
  props: {
    items: Array,
    selectedItemsIdentifiers: Array,
    displayVariantsDialogAuto: Boolean,
    isStockInfoAvailable: Boolean,
    context: String,
  },
  data: () => ({
    displayVariantsDialog: false,
    displayMenuDialog: false,
    displayStockDialog: false,
    displayOpenItemInputDialog: false,
    showStock: false,
    selectedItem: {},
    selectedVariant: {},
  }),
  methods: {
    /**
     * This function is triggered when the user selects an Item to access its detail;
     * this is a redirect to the Items' detail interface.
     * @param pk
     **/ goToDetail: function (pk) {
      this.$router.push({
        name: "catalog-item-detail",
        params: {
          id: pk,
        },
      });
    },
    /**
     * Adding a new Item to the Cart, which is stored on the local storage
     * TODO: dizer que agora faz mais do que adicionar ao carrinho
     * @param item
     */
    addToCart: function (item) {
      if (this.context && this.context === "menu") {
        this.$emit("select-item", item);
      } else {
        //@modified ana.castro 2024.02.27 SAFO-94
        // &&  this.displayVariantsDialogAuto
        if (item.data && item.data.variants && Object.keys(item.data.variants).length > 0) {
          this.selectedItem = item;
          this.displayVariantsDialog = true;
        } else if (item.data && item.data.menuLevels && Object.keys(item.data.menuLevels).length > 0) {
          this.selectedItem = item;
          this.displayMenuDialog = true;
        } else if (item.data.item_type && item.data.item_type === 5) {
          this.selectedItem = item;
          this.displayOpenItemInputDialog = true;
        } else {
          let itemId = item.id;
          let parentItemId = item.data.parentItem;
          let menuChoices = null;
          this.$store.dispatch("cart/addToCart", { itemId, parentItemId, menuChoices });
        }
      }

      // this.$emit("create-customer", this.newCustomer);
    },
    showItemStock: function (item) {
      this.selectedItem = item;
      this.displayStockDialog = true;
    },
    closeDialog: function () {
      this.displayVariantsDialog = false;
      this.displayStockDialog = false;
      this.displayOpenItemInputDialog = false;
      this.showStock = false;
      this.selectedVariant = {};
    },
    addVariant: function (parentItemId, variantValues) {
      let variantsMatch = false;
      // this.$store.getters["items/get"](parentItemId).then((result) => {
      this.$store.dispatch("items/get", parentItemId).then((result) => {
        let parentItem = result[0];
        for (let i = 0; i < parentItem.data.variantsDetails.length; i++) {
          if (
            parentItem.data.variantsDetails[i].status === undefined ||
            parentItem.data.variantsDetails[i].status === 1
          ) {
            variantsMatch = true;
            for (const key in variantValues) {
              if (variantValues[key].value !== parentItem.data.variantsDetails[i].variants[key]) {
                variantsMatch = false;
                break;
              }
            }
            if (variantsMatch) {
              let itemId = parentItem.data.variantsDetails[i].id;
              let parentItemId = parentItem.id;
              let menuChoices = null;
              this.$store.dispatch("cart/addToCart", { itemId, parentItemId, menuChoices });
              break;
            }
          }
        }
        if (!variantsMatch) {
          this.$store.dispatch("alert/error", this.$vuetify.lang.t("$vuetify.itemNotFoundMessage"));
        }
      });

      this.displayVariantsDialog = false;
    },
    addInput: function (itemId, extraInfo) {
      this.$store.dispatch("items/get", itemId).then((result) => {
        if (result[0]) {
          let parentItemId = null;
          let menuChoices = null;
          this.$store.dispatch("cart/addToCart", { itemId, parentItemId, menuChoices, extraInfo });
        }
      });

      this.displayOpenItemInputDialog = false;
    },
    showVariantStock: function (parentItemId, variantValues) {
      let variantsMatch = false;
      this.$store.dispatch("items/get", parentItemId).then(
        (result) => {
          let parentItem = result[0];
          for (let i = 0; i < parentItem.data.variantsDetails.length; i++) {
            if (
              parentItem.data.variantsDetails[i].status === undefined ||
              parentItem.data.variantsDetails[i].status === 1
            ) {
              variantsMatch = true;
              for (const key in variantValues) {
                if (variantValues[key].value !== parentItem.data.variantsDetails[i].variants[key]) {
                  variantsMatch = false;
                  break;
                }
              }
              if (variantsMatch) {
                this.showStock = true;
                this.selectedVariant = parentItem.data.variantsDetails[i];
                break;
              } else {
                this.selectedVariant = {};
              }
            }
          }
          if (Object.keys(this.selectedVariant).length === 0) {
            this.$store.dispatch("alert/error", this.$vuetify.lang.t("$vuetify.msgContentNotAvailable"));
          }
        },
        (error) => {
          this.$store.dispatch("loader/setLoading", false);
          this.$store.dispatch("alert/error", this.$vuetify.lang.t("$vuetify.msgContentNotAvailable"));
          getErrorMessage(error, "Display Item Stock", this);
        }
      );
    },

    closeMenuDialog: function () {
      this.displayMenuDialog = false;
    },
  },
  computed: {
    ...mapGetters({
      currencySymbol: "configs/currencySymbol",
      baseUrl: "configs/apiImgBaseUrl",
      itemsDescription: "configs/itemsDescription",
    }),
    selectedItemsIdentifiersC() {
      return this.selectedItemsIdentifiers;
    },
  },
  watch: {
    /**
     * Depending on the Items content there is an automatism which may be triggered:
     *  if there is only one Item on the list and if that Item has variants,
     *  a dialog to select the Variants must be automatically displayed.
     */
    items() {
      if (
        this.displayVariantsDialogAuto &&
        this.items.length === 1 &&
        Object.keys(this.items[0].data.variants).length > 0
      ) {
        this.addToCart(this.items[0]);
      } else if (this.items.length === 1 && this.items[0].data.item_type && this.items[0].data.item_type === 5) {
        this.addToCart(this.items[0]);
      } else {
        this.displayVariantsDialog = false;
      }
    },
  },
};
</script>

<style>
.catalog-item-card-selected > .catalog-item-card-title {
  background-color: #028940;
  color: #ffffff;
}

.custom-dialog.v-dialog:not(.v-dialog--fullscreen) {
  height: 90%;
  width: 90%;
}
</style>
