<template>
  <v-form ref="form" v-model="valid" @submit.prevent="" v-if="ready">
    <v-card>
      <v-card-title>{{ item.description }}</v-card-title>
      <v-card-subtitle>{{ item.code }}</v-card-subtitle>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="input_code"
                ref="addInputCode"
                :label="$vuetify.lang.t('$vuetify.voucherCode')"
                :placeholder="$vuetify.lang.t('$vuetify.voucherCode')"
                :counter="true"
                single-line
                clear-icon="fal fa-times"
                type="number"
                :rules="voucherCodeRules"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <div>
          <v-btn large text @click="closeDialog"> {{ $vuetify.lang.t("$vuetify.cancel") }} </v-btn>
          <v-btn large :disabled="!valid" class="primary" @click="addInput">
            {{ $vuetify.lang.t("$vuetify.add") }}</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  name: "OpenItemInputCard",
  props: {
    item: Object,
  },
  data: () => ({
    input_code: "",
    valid: true,
    ready: false,
  }),
  methods: {
    addInput: function () {
      this.$emit("add-input", this.item.id, this.input_code);
    },
    closeDialog: function () {
      this.$emit("close-dialog");
    },
  },
  computed: {
    voucherCodeRules: function () {
      return [
        (v) => !!v || this.$vuetify.lang.t("$vuetify.mandatoryField"),
        (v) => (v && /^100\d*$/.test(v)) || this.$vuetify.lang.t("$vuetify.value100"),
        (v) => (v && /^\d{10,20}$/.test(v)) || this.$vuetify.lang.t("$vuetify.value10to20"),
      ];
    },
  },
  created() {
    this.ready = true;
  },
};
</script>

<style scoped></style>
