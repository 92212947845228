<template>
  <v-form ref="form" lazy-validation>
    <v-card>
      <div>
        <v-card-title v-if="item.mainDescription">{{ item.mainDescription }}</v-card-title>
        <v-card-title v-else>{{ item.description }}</v-card-title>
        <v-card-subtitle>{{ item.code }}</v-card-subtitle>

        <ItemsVariants
          v-if="itemHasVariants"
          :item="item"
          :variants="item.data.variants"
          @close-dialog="closeDialog"
          @show-variant-stock="showVariantStock"
        >
        </ItemsVariants>

        <div v-if="!itemHasVariants || showStock">
          <v-card-text v-show="itemsStockValues">
            <div v-if="!itemsStockValues || !Object.keys(itemsStockValues).length">
              <div>
                <v-btn icon>
                  <v-avatar color="red" size="20"></v-avatar>
                </v-btn>
                {{ $vuetify.lang.t("$vuetify.noStock") }}
              </div>
            </div>

            <table v-else>
              <tr v-for="(warehouseValues, i) in itemsStockValues" :key="i">
                <td>
                  {{ warehouseValues.WarehouseName }}
                </td>
                <td style="padding-left: 20px">
                  <v-btn icon>
                    <v-avatar v-if="warehouseValues.PhysicalQty > 0" color="green" size="20"></v-avatar>
                    <v-avatar v-else color="red" size="20"></v-avatar>
                  </v-btn>
                  {{ warehouseValues.PhysicalQty }}
                </td>
              </tr>
            </table>
          </v-card-text>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large text @click="closeDialog"> {{ $vuetify.lang.t("$vuetify.close") }}</v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-form>
</template>

<script>
import { itemsService } from "@/services";
import { getErrorMessage } from "@/_helpers";
import ItemsVariants from "@/components/ItemsVariants.vue";

export default {
  name: "ItemsStockCard",
  components: { ItemsVariants },
  props: {
    item: Object,
    variant: Object,
    showStock: Boolean,
  },
  data: () => ({
    itemsStockValues: null,
    itemHasVariants: false,
  }),
  methods: {
    getItemStock() {
      // Update itemsStockValues variable
      this.checkIfItemHasVariants();

      // Get stock if item has no variants or if item is a variant
      if (!this.itemHasVariants || Object.keys(this.variant).length) {
        this.$store.dispatch("loader/setLoading", true);

        // Call stocks API, filter items with positive stock and group by code
        itemsService.getStock(this.variant.code || this.item.code).then(
          (result) => {
            this.itemsStockValues = result
              .map((result) => ({
                ...result,
                PhysicalQty: Math.max(result["PhysicalQty"], 0),
              }))
              .sort(function (a, b) {
                return a.WarehouseName.localeCompare(b.WarehouseName);
              });

            this.$store.dispatch("loader/setLoading", false);
          },
          (error) => {
            this.$store.dispatch("loader/setLoading", false);
            this.$store.dispatch("alert/error", this.$vuetify.lang.t("$vuetify.msgContentNotAvailable"));
            getErrorMessage(error, "Display Item Stock", this);
          }
        );
      } else if (Object.keys(this.variant).length === 0) {
        this.itemsStockValues = null;
      }
    },
    checkIfItemHasVariants() {
      if (Object.keys(this.item.data.variants).length) {
        this.itemHasVariants = true;
      }
    },
    closeDialog: function () {
      this.$emit("close-dialog");
    },
    showVariantStock: function (parentItemId, variantValues) {
      this.$emit("show-variant-stock", parentItemId, variantValues);
    },
  },
  watch: {
    variant() {
      if (this.variant) {
        this.getItemStock();
      }
    },
  },
  mounted() {
    this.getItemStock();
  },
};
</script>

<style scoped></style>
