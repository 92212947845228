<template>
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title v-if="item.mainDescription">{{ item.mainDescription }}</v-card-title>
      <v-card-title v-else>{{ item.description }}</v-card-title>
      <v-card-subtitle>{{ item.code }}</v-card-subtitle>

      <ItemsVariants
        :item="item"
        :variants="item.data.variants"
        :addToCart="true"
        @close-dialog="closeDialog"
        @add-variant="addVariant"
      >
      </ItemsVariants>
    </v-card>
  </v-form>
</template>

<script>
import ItemsVariants from "@/components/ItemsVariants.vue";

export default {
  name: "ItemsVariantsCard",
  components: { ItemsVariants },
  props: {
    item: Object,
    variants: Object,
    showStock: Boolean,
  },
  data: () => ({
    variantValues: {},
  }),
  methods: {
    addVariant: function (parentItemId, variantValues) {
      this.$emit("add-variant", parentItemId, variantValues);
    },
    closeDialog: function () {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style scoped></style>
